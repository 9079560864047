import classNames from 'classnames';
import React, { forwardRef } from 'react';

export enum ButtonTheme {
    Primary = 'primary',
    Outline = 'outline',
    White = 'white'
}

export enum ButtonSize {
    Small = 'sm',
    Medium = 'md',
    Large = 'lg',
    ExtraLarge = 'xl',
    ExtraExtraLarge = '2xl'
}

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    className?: string;
    size?: ButtonSize;
    theme?: ButtonTheme;
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: () => void;
    children: React.ReactNode | string;
}

const Button = (
    props: ButtonProps,
    ref: React.LegacyRef<HTMLButtonElement> | undefined
) => {
    const {
        className,
        size = ButtonSize.Medium,
        theme = ButtonTheme.Primary,
        disabled = false,
        isLoading = false,
        onClick = () => {},
        children,
        ...otherProps
    } = props;
    const sizeClasses = classNames({
        'text-sm px-4 py-2': size === 'sm',
        'text-sm px-5 py-2.5': size === 'md',
        'text-md px-6 py-3': size === 'lg',
        'text-lg px-8 py-3': size === 'xl',
        'text-xl px-10 py-3': size === '2xl'
    });
    const themeClasses = classNames({
        'bg-fs-primary text-white hover:bg-fs-primary-light':
            theme === ButtonTheme.Primary,
        'bg-white text-black border border-fs-primary hover:bg-fs-primary-100':
            theme === ButtonTheme.Outline,
        'bg-white text-black hover:bg-gray-100': theme === ButtonTheme.White
    });
    const mods = classNames({
        'cursor-not-allowed opacity-50 pointer-events-none':
            disabled || isLoading
    });

    return (
        <button
            ref={ref}
            className={classNames(
                'font-medium rounded-lg focus:outline-none transition-all whitespace-nowrap',
                className,
                themeClasses,
                sizeClasses,
                mods
            )}
            onClick={onClick}
            {...otherProps}
        >
            {isLoading ? 'Loading...' : children}
        </button>
    );
};

export default forwardRef(Button);
