export enum SUBDOMAINS {
    AUTH = 'auth',
    THREAT_INTELLIGENCE = 'threat-intelligence'
}

export enum PATHS {
    HOME = '/',
    ABOUT_US = '/about-us',
    DOCUMENTATION = '/documentation',
    FAQ = '/faq',
    LOGIN = '/login',
    REGISTER = '/register',
    CONTACT_SALES = '/contact-sales',
    TERMS_OF_SERVICE = '/terms-of-service',
    PRIVACY_POLICY = '/privacy-policy',
    STATISTICS = '/statistics',
    THREAT_STATISTICS = '/threat-statistics',
    THREAT_DATABASE = '/threat-database',
    THREAT_DATABASE_DOCUMENTATION = '/threat-database/documentation',
    THREAT_LOOKUP = '/threat-lookup',
    THREAT_API = '/threat-api',
    THREAT_API_DOCUMENTATION = '/threat-api/documentation',
    STATISTICS_BY_COUNTRY = '/statistics/country',
    STATISTICS_BY_COMPANY = '/statistics/company',
    STATISTICS_BY_BLOCK_SIZE = '/statistics/block-size',
    STATISTICS_BY_DATE = '/statistics/date'
}

export const FADE_IN_ANIMATION_SETTINGS = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.2 }
};

export const FADE_IN_ANIMATION_VARIANTS = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { type: 'spring' } }
};

export const FADE_DOWN_ANIMATION_VARIANTS = {
    hidden: { opacity: 0, y: -10 },
    show: { opacity: 1, y: 0, transition: { type: 'spring' } }
};

export const FADE_UP_ANIMATION_VARIANTS = {
    hidden: { opacity: 0, y: 10 },
    show: { opacity: 1, y: 0, transition: { type: 'spring' } }
};

export const safeDocument: Document | undefined =
    typeof document === 'undefined' ? undefined : document;
