import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import { dateToString } from '@/shared/lib/dates';
import { AppLink } from '@/shared/ui/AppLink';
import React from 'react';

interface LinkOptions {
    subdomain?: string;
    path?: string;
}

export function composeLink({
    subdomain = undefined,
    path = PATHS.HOME
}: LinkOptions): string {
    const IS_DEV = false;
    const protocol = process.env.NEXT_PUBLIC_PROTOCOL || 'https';
    const hostname =
        process.env.NEXT_PUBLIC_HOSTNAME ||
        process.env.VERCEL_URL ||
        'localhost';
    const port = process.env.NEXT_PUBLIC_PORT || '';
    const portString = port ? `:${port}` : '';
    const subdomainString = subdomain && subdomain !== SUBDOMAINS.THREAT_INTELLIGENCE ? `${subdomain}.` : '';

    if (IS_DEV) {
        const subdomainString = subdomain ? `/[subdomain]/${subdomain}` : '';
        return `${protocol}://${hostname}${portString}${subdomainString}${path}`;
    }

    return `${protocol}://${subdomainString}${hostname}${portString}${path}`;
}
