import { createContext } from 'react';

export enum Layout {
    Lookup = 'lookup',
    Default = 'default'
}

export interface LayoutContextProps {
    layout: Layout;
    setLayout: (layout: Layout) => void;
}

export const LayoutContext = createContext<LayoutContextProps>({
    layout: Layout.Lookup,
    setLayout: () => {}
});
