import { useContext } from 'react';
import { Layout, LayoutContext } from './LayoutContext';

interface UseLayout {
    layout: Layout;
    setLayout: (layout: Layout) => void;
}

function useLayout(): UseLayout {
    const { layout, setLayout } = useContext(LayoutContext);

    return {
        layout,
        setLayout
    };
}

export default useLayout;
