import React, { useMemo, useState } from 'react';
import { Layout, LayoutContext } from '../lib/LayoutContext';

const defaultLayout = Layout.Lookup;

interface LayoutProviderProps {
    children: React.ReactNode;
}

const LayoutProvider = (props: LayoutProviderProps) => {
    const { children } = props;
    const [layout, setLayout] = useState<Layout>(defaultLayout);

    const defaultProps = useMemo(
        () => ({
            layout,
            setLayout
        }),
        [layout, setLayout]
    );

    return (
        <LayoutContext.Provider value={defaultProps}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutProvider;
